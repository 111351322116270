import React from "react"

import SEO from "src/components/seo"
import Layout from "src/components/Layout"

import AboutUs from "src/components/AboutUs"

const QuemSomos = () => (
  <Layout>
    <SEO title="Quem Somos" />
    <AboutUs />
  </Layout>
)

export default QuemSomos
