import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import ContactUsForm from 'src/components/ContactUsForm'

import {MarkdownContent} from 'src/styles/base'
import * as S from './styled'

const AboutUs = () => {
  const {
    strapiQuemSomos: { titulo, descricao, banner }
  } = useStaticQuery(
    graphql`
      query {
        strapiQuemSomos {
          banner {
            childImageSharp {
              fluid(maxWidth: 1900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          titulo
          descricao
        }
      }
    `
  );

  return (
    <>
      <S.Banner fluid={banner.childImageSharp.fluid} />
      <S.ContentWrapper>
        <S.InfosWrapper>
          <S.Title>
            {titulo}
          </S.Title>
          <S.Description>
            <MarkdownContent>
              {descricao}
            </MarkdownContent>
          </S.Description>
        </S.InfosWrapper>

        <S.FormWrapper>
          <ContactUsForm 
            backgroundLight={true}
          />
        </S.FormWrapper>
      </S.ContentWrapper>
    </>
  )
}

export default AboutUs